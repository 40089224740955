import React from "react";
import "../../styles/IndividualProjects.css";
import { Link } from "react-router-dom";
import MovingImage from "../../components/MovingImage";
const Header = React.lazy(() => import("../../components/Header"));
const Footer = React.lazy(() => import("../../components/Footer"));
const CaseStudyBanner = React.lazy(() =>
  import("../../components/CaseStudyBanner")
);

const SinbitTrades = () => {
  return (
    <>
      <Header />

      <CaseStudyBanner
        imageSrc="/images/caseStudy1.webp"
        imageAlt="asdfadsfadsfsadf"
        caseStudyName="Sinbit-Trades"
      />

      <section>
        <div className="individualProject">
          <section className="individualProject__aboutBrand">
            <div className="individualProject__aboutBrand__left">
              {" "}
              <img
                src="https://w7.pngwing.com/pngs/275/961/png-transparent-chanel-logo-brand-gucci-logo-text-trademark-fashion.png"
                width={150}
                height={150}
                loading="lazy"
                className="individualProject__aboutBrand__left__img"
              />
            </div>

            <div className="individualProject__aboutBrand__right">
              <div>
                <h6 className="individualProject__aboutBrand__right__title">
                  About Brand
                </h6>
              </div>

              <div>
                <p className="individualProject__aboutBrand__right__text">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis dolorem id debitis ratione non architecto animi
                  voluptas voluptatum, natus nemo, et tenetur nobis eveniet
                  quaerat? Et iste id ipsum dignissimos.
                </p>
              </div>
            </div>
          </section>
        </div>

        <div className="individualProject">
          <section className="individualProject__aboutBrand">
            <div className="individualProject__aboutBrand__left">
              {" "}
              <img
                src="https://w7.pngwing.com/pngs/275/961/png-transparent-chanel-logo-brand-gucci-logo-text-trademark-fashion.png"
                width={150}
                height={150}
                loading="lazy"
                className="individualProject__aboutBrand__left__img"
              />
            </div>

            <div className="individualProject__aboutBrand__right">
              <div>
                <h6 className="individualProject__aboutBrand__right__title">
                  Project Overview
                </h6>
              </div>

              <div>
                <p className="individualProject__aboutBrand__right__text">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis dolorem id debitis ratione non architecto animi
                  voluptas voluptatum, natus nemo, et tenetur nobis eveniet
                  quaerat? Et iste id ipsum dignissimos.
                </p>
              </div>
            </div>
          </section>
        </div>

        <div className="individualProject">
          <section className="individualProject__aboutBrand">
            <div className="individualProject__aboutBrand__left">
              {" "}
              <img
                src="https://w7.pngwing.com/pngs/275/961/png-transparent-chanel-logo-brand-gucci-logo-text-trademark-fashion.png"
                width={150}
                height={150}
                loading="lazy"
                className="individualProject__aboutBrand__left__img"
              />
            </div>

            <div className="individualProject__aboutBrand__right">
              <div>
                <h6 className="individualProject__aboutBrand__right__title">
                  Project Challenges
                </h6>
              </div>

              <div>
                <p className="individualProject__aboutBrand__right__text">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Perspiciatis dolorem id debitis ratione non architecto animi
                  voluptas voluptatum, natus nemo, et tenetur nobis eveniet
                  quaerat? Et iste id ipsum dignissimos.
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="section2 col-12 ">
        <div className="row">
          <div className="col-7">
            <div className="section2__card__right">
              <div className="section2__card__right__roles">
                <h5 className="section2__card__right__roles__title">
                  Our Roles
                </h5>
                <ul>
                  <li className="section2__card__right__roles__li">
                    Web Design | Web Development
                  </li>
                  <li className="section2__card__right__roles__li">
                    E-commerce
                  </li>
                  <li className="section2__card__right__roles__li">
                    Website Maintenance
                  </li>
                  <li className="section2__card__right__roles__li">
                    PPC & Digital Advertising | SEO
                  </li>
                  <li className="section2__card__right__roles__li">SEO</li>
                </ul>
              </div>

              <div className="section2__card__right__roles">
                <h5 className="section2__card__right__roles__title">
                  Tech Stack
                </h5>
                <ul>
                  <li className="section2__card__right__roles__li">
                    Web Design | Web Development
                  </li>
                  <li className="section2__card__right__roles__li">
                    E-commerce
                  </li>
                  <li className="section2__card__right__roles__li">
                    Website Maintenance
                  </li>
                  <li className="section2__card__right__roles__li">
                    PPC & Digital Advertising | SEO
                  </li>
                  <li className="section2__card__right__roles__li">SEO</li>
                </ul>
              </div>

              {/* <div className="section2__card__right__roles">
                <h5 className="section2__card__right__roles__title">Result</h5>
                <p className="section2__card__right__roles__resultText">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Quibusdam nesciunt illum excepturi repellat ratione hic,
                  quisquam architecto dolore? Repellendus provident nisi nemo
                  blanditiis, atque cum quo distinctio sequi error eos!
                </p>
              </div> */}

              <h3>
                {" "}
                <Link
                  to="https://sinbittrades.com/"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#323232" }}
                >
                  {" "}
                  Live Website{" "}
                </Link>{" "}
              </h3>
            </div>
          </div>

          <div className="col-5">
            <div className="inquireNow">
              <div>
                <h6 className="inquireNow__title">Inquire Now</h6>
              </div>

              <div className="mt-5">
                <form>
                  <div className="row mb-3">
                    <div className="form-group col-6">
                      <label htmlFor="fullName">Full Name</label>
                      <input
                        type="email"
                        className="form-control"
                        id="fullName"
                        aria-describedby="emailHelp"
                      />
                    </div>

                    <div className="form-group col-6">
                      <label htmlFor="telephone">Telephone</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="telephone"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="email">Your Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="yourMessage">Your Message</label>
                    <div>
                      <textarea rows={6} style={{ width: "100%" }}></textarea>
                    </div>
                  </div>

                  <div className="inquireNow__submitBtn">
                    <span>Submit</span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="allCaseStudies ">
        <h3 className="text-center py-3"> More Pages </h3>
        <div className="row">
          {[
            {
              id: 1,
              cardName: "Sinbit-Trades",
              routeLink: "/case-studies/sinbit-trades/",
              imgSrc: "/images/projects/sinbit.png",
              imgAlt: "Sinbit Trades",
            },
            {
              id: 2,
              cardName: "Project 2",
              routeLink: "",
              imgSrc: "/images/projects/sinbit.png",
              imgAlt: "",
            },
            {
              id: 3,
              cardName: "Project 3",
              routeLink: "",
              imgSrc: "/images/projects/sinbit.png",
              imgAlt: "",
            },
            {
              id: 4,
              cardName: "Project 4",
              routeLink: "",
              imgSrc: "/images/projects/sinbit.png",
              imgAlt: "",
            },
            {
              id: 5,
              cardName: "Project 5",
              routeLink: "",
              imgSrc: "/images/projects/sinbit.png",
              imgAlt: "",
            },
            {
              id: 6,
              cardName: "Project 6",
              routeLink: "",
              imgSrc: "/images/projects/sinbit.png",
              imgAlt: "",
            },
            {
              id: 7,
              cardName: "Project 7",
              routeLink: "",
              imgSrc: "/images/projects/sinbit.png",
              imgAlt: "",
            },
          ].map((data, idx) => {
            return (
              <div key={idx} className="col-4 mb-5">
                <div className="allCaseStudies__card">
                  <div>
                    <MovingImage
                      imgBoxHeight="300px"
                      imgSrc={data.imgSrc}
                      imgAlt={data.imgAlt}
                      width={"100%"}
                      height={"auto"}
                    />
                  </div>

                  <div className="allCaseStudies__card__titleContainer">
                    <Link
                      to={data.routeLink}
                      className="allCaseStudies__card__titleContainer__title"
                    >
                      {data.cardName}
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SinbitTrades;
