import "./App.css";
import { Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import SinbitTrades from "./pages/ProjectCaseStudy/SinbitTrades";
import ClothEcommerce from "./pages/ProjectCaseStudy/ClothEcommerce";
import MobileKart from "./pages/ProjectCaseStudy/MobileKart";
import PersonalPortfolio from "./pages/ProjectCaseStudy/PersonalPortfolio";
import ExpenseTracker from "./pages/ProjectCaseStudy/ExpenseTracker";
import ChattingApp from "./pages/ProjectCaseStudy/ChattingApp";
import ImagesBazar from "./pages/ProjectCaseStudy/ImagesBazar";

const HomePage = React.lazy(() => import("./pages/HomePage"));
const CaseStudy = React.lazy(() => import("./pages/CaseStudy"));

function App() {
  return (
    <Suspense fallback={<p> ... Loading </p>}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/case-studies/" element={<CaseStudy />} />
        <Route path="/case-studies/sinbit-trades/" element={<SinbitTrades />} />
        <Route
          path="/case-studies/cloth-ecommerce/"
          element={<ClothEcommerce />}
        />
        <Route path="/case-studies/mobilekart/" element={<MobileKart />} />
        <Route
          path="/case-studies/personal-portfolio/"
          element={<PersonalPortfolio />}
        />
        <Route
          path="/case-studies/expense-tracker/"
          element={<ExpenseTracker />}
        />
        <Route path="/case-studies/chatting-app/" element={<ChattingApp />} />
        <Route path="/case-studies/images-bazar/" element={<ImagesBazar />} />
      </Routes>
    </Suspense>
  );
}

export default App;
