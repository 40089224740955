import React from "react";

const MovingImage = ({
  imgSrc,
  imgAlt,
  width,
  height,
  objectFit,
  imgBoxHeight,
}) => {
  return (
    <div className="movingImgContainer" style={{ height: `${imgBoxHeight}` }}>
      <img
        src={imgSrc}
        alt={imgAlt}
        className="movingImgContainer__image"
        width={width}
        height={height}
        style={{ objectFit: `${objectFit}` }}
      />
    </div>
  );
};

export default MovingImage;
